<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col lg="2" md="12">
        <b-card>
          <h2>Number of registered users</h2>
        </b-card>
      </b-col>
      <b-col lg="2" md="12">
        <b-card>
          <h6>Number of registered users in in Vietnam region</h6>
          <h2 class="font-weight-bolder mb-1">
            {{ users.userRegisterRegionVN }}
          </h2>
        </b-card>
      </b-col>
      <b-col lg="2" md="12">
        <b-card>
          <h6>Number of registered users in in Singapore region</h6>
          <h2 class="font-weight-bolder mb-1">
            {{ users.userRegisterRegionSG }} 
          </h2>
        </b-card>
      </b-col>
      <b-col lg="2" md="12">
        <b-card>
          <h6>Number of registered users in in Thailand region</h6>
          <h2 class="font-weight-bolder mb-1">
            {{ users.userRegisterRegionTH }} 
          </h2>
        </b-card>
      </b-col>
      <b-col lg="2" md="12">
        <b-card>
          <h6>Number of registered users in in Indonesia region</h6>
          <h2 class="font-weight-bolder mb-1">
            {{ users.userRegisterRegionID }} 
          </h2>
        </b-card>
      </b-col>
      <b-col lg="2" md="12">
        <b-card>
          <h6>Number of registered users in in Malaysia region</h6>
          <h2 class="font-weight-bolder mb-1">
            {{ users.userRegisterRegionMY }} 
          </h2>
        </b-card>
      </b-col>
      <b-col lg="2" md="12">
        <b-card>
          <h6>Number of registered users in in Japan region</h6>
          <h2 class="font-weight-bolder mb-1">
            {{ users.userRegisterRegionJP }} 
          </h2>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col lg="2" md="12">
        <b-card>
          <h2>Value of sales</h2>
        </b-card>
      </b-col>
      <b-col lg="2" md="12">
        <b-card>
          <h6>Value of sales in Vietnam region</h6>
          <h2 class="font-weight-bolder mb-1">
            {{ kFormatter(sales.orderPaymentRegionVN) }} VND
          </h2>
        </b-card>
      </b-col>
      <b-col lg="2" md="12">
        <b-card>
          <h6>Value of sales in Singapore region</h6>
          <h2 class="font-weight-bolder mb-1">
            {{ kFormatter(sales.orderPaymentRegionSG) }} SGD
          </h2>
        </b-card>
      </b-col>
      <b-col lg="2" md="12">
        <b-card>
          <h6>Value of sales in Thailand region</h6>
          <h2 class="font-weight-bolder mb-1">
            {{ kFormatter(sales.orderPaymentRegionTH) }} THB
          </h2>
        </b-card>
      </b-col>
      <b-col lg="2" md="12">
        <b-card>
          <h6>Value of sales in Indonesia region</h6>
          <h2 class="font-weight-bolder mb-1">
            {{ kFormatter(sales.orderPaymentRegionID) }} IDR
          </h2>
        </b-card>
      </b-col>
      <b-col lg="2" md="12">
        <b-card>
          <h6>Value of sales in Malaysia region</h6>
          <h2 class="font-weight-bolder mb-1">
            {{ kFormatter(sales.orderPaymentRegionMY) }} MYR
          </h2>
        </b-card>
      </b-col>
      <b-col lg="2" md="12">
        <b-card>
          <h6>Value of sales in Japan region</h6>
          <h2 class="font-weight-bolder mb-1">
            0 JPY
            <!-- {{ kFormatter(sales.orderPaymentRegionJP) }} JPY -->
          </h2>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col lg="4" md="12">
        <b-card>
          <h6>Orders</h6>
          <h2 class="font-weight-bolder mb-1">
            {{ totalOrders.length }}
          </h2>
          <b-table :items="totalOrders.slice(0, 5)" responsive :fields="totalOrdersFields">
            <!-- id -->
            <template #cell(id)="data">
              <div class="d-flex align-items-center">
                <b-link :to="{ name: 'order-detail', params: { id: data.item.id } }">#{{ data.item.id }}</b-link>
              </div>
            </template>

          </b-table>
        </b-card>
      </b-col>
      <b-col lg="4" md="12">
        <b-card>
          <h6>Orders offline</h6>
          <h2 class="font-weight-bolder mb-1">
            {{ ordersOffline.length }}
          </h2>
          <b-table :items="ordersOffline.slice(0, 5)" responsive :fields="ordersOfflineFields">
            <!-- id -->
            <template #cell(id)="data">
              <div class="d-flex align-items-center">
                <b-link :to="{ name: 'order-detail', params: { id: data.item.id } }">#{{ data.item.id }}</b-link>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
      <b-col lg="4" md="12">
        <b-card>
          <h6>Orders online</h6>
          <h2 class="font-weight-bolder mb-1">
            {{ ordersOnline.length }}
          </h2>
          <b-table :items="ordersOnline.slice(0, 5)" responsive :fields="ordersOnlineFields">
            <!-- id -->
            <template #cell(id)="data">
              <div class="d-flex align-items-center">
                <b-link :to="{ name: 'order-detail', params: { id: data.item.id } }">#{{ data.item.id }}</b-link>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col lg="6" md="12">
        <b-card>
          <h6>Items currently in cart</h6>
          <h2 class="font-weight-bolder mb-1">
            {{ cart.length }}
          </h2>
        </b-card>
      </b-col>
      <b-col lg="6" md="12">
        <b-card>
          <h6>Items currently in wishlist</h6>
          <h2 class="font-weight-bolder mb-1">
            {{ wishlist.length }}
          </h2>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BCard, BTable, BLink } from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { kFormatter } from '@core/utils/filter'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BLink
  },
  data() {
    return {
      totalOrders: [],
      totalOrdersFields: [
        { key: 'id', label: 'Order number' },
        { key: 'email', label: 'Email' },
      ],
      ordersOffline: [],
      ordersOfflineFields: [{ key: 'id', label: 'Order number' },
      { key: 'email', label: 'Email' },],
      ordersOnline: [],
      ordersOnlineFields: [{ key: 'id', label: 'Order number' },
      { key: 'email', label: 'Email' },],
      cart: [],
      sales: {},
      users: {},
      wishlist: []
    }
  },
  created() {
    this.loadDataReport()
  },
  methods: {
    kFormatter,
    loadDataReport() {
      store.dispatch('report/fetchSummary').then(res => {
        this.totalOrders = [...res.body.orders.orderPaymentOffline, ...res.body.orders.orderPaymentOnline]
        this.ordersOnline = res.body.orders.orderPaymentOnline
        this.ordersOffline = res.body.orders.orderPaymentOffline
        this.cart = res.body.cartItem
        this.wishlist = res.body.wishlist
        this.sales = res.body.salesReport
      })
      store.dispatch('report/fetchUsersRegister').then(res => {
        this.users = res.body
      })
    }
  },
}
</script>
